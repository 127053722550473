export enum VesselCallJobStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Acknowledged = 'Acknowledged',
  Quoted = 'Quoted',
  Active = 'Active',
  Closed = 'Closed',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected'
}

export type VesselCallJobStatusDescriptions = 'Draft' | 'Pending' | 'Acknowledged' | 'Quoted' | 'Active' | 'Closed' | 'Cancelled' | 'Rejected';
export const VesselCallJobStatusLookup: Record<VesselCallJobStatusDescriptions, VesselCallJobStatus> = {
  Draft: VesselCallJobStatus.Draft,
  Pending: VesselCallJobStatus.Pending,
  Acknowledged: VesselCallJobStatus.Acknowledged,
  Quoted: VesselCallJobStatus.Quoted,
  Active: VesselCallJobStatus.Active,
  Closed: VesselCallJobStatus.Closed,
  Cancelled: VesselCallJobStatus.Cancelled,
  Rejected: VesselCallJobStatus.Rejected,
};

export const VesselCallJobStatusOrderLookup: Record<VesselCallJobStatusDescriptions, number> = {
  Draft: 1,
  Pending: 2,
  Acknowledged: 3,
  Quoted: 4,
  Active: 5,
  Closed: 6,
  Cancelled: 7,
  Rejected: 8,
};

export const VesselCallJobStatusLookupList = (Object.keys(VesselCallJobStatusLookup) as Array<VesselCallJobStatusDescriptions>);
