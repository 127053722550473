import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IOrder } from 'src/app/shared/model/order/order.interface';
import { IDaTotalPerStageAndCurrencyDto } from 'src/app/vessel-call/model/da-total-per-stage-and-currency.dto';
import { DaApprovalQuotationDto } from 'src/app/vessel-call/model/da/da-approval-quotation.dto';
import { IOrderServicePortCallDto } from 'src/app/vessel-call/model/order-service-port-call.dto';
import { DraftVesselCallRequestDto } from 'src/app/vessel-call/model/order/draft-vessel-call-request.dto';
import { IVesselCallRequestIndex } from 'src/app/vessel-call/model/order/vessel-call-request-index.dto';
import { IVesselCallRequest } from 'src/app/vessel-call/model/order/vessel-call-request.dto';
import { VesselCallJobStatus } from 'src/app/vessel-call/model/order/vessel-call/vessel-call-status';
import { environment } from 'src/environments/environment';
import { IPostResponseDto } from '../model/post-response.dto';
import { DaApprovalCostSummaryDto } from './../../vessel-call/model/da/da-approval-cost-summary.dto';
import { PageResponseDto } from './../model/page-response.dto';

const orderServiceUrl = environment.orderServiceApiUrl;

@Injectable({
  providedIn: 'root'
})
export class OrderDataService {

  constructor(private httpClient: HttpClient) { }

  approveQuotation(requestDto: DaApprovalQuotationDto): Observable<IPostResponseDto<IOrder>> {
    return this.httpClient.post<IPostResponseDto<IOrder>>(`${orderServiceUrl}/orders/pdaapproval`, requestDto);
  }

  cancelRequest(orderId: string): Observable<IPostResponseDto<IOrder>> {
    return this.httpClient.post<IPostResponseDto<IOrder>>(`${orderServiceUrl}/orders/cancelrequest?orderId=${orderId}`, {});
  }

  //TO BE UPDATED TO SUPPORT GROUP CODE
  rejectQuotation(refereceNumber: string): Observable<unknown> {
    return this.httpClient.post<unknown>(`${orderServiceUrl}/quotation/rejectquotation?orderNo=${refereceNumber}`, {});
  }

  approveCostSummary(costSummaryDto: DaApprovalCostSummaryDto, isGroupCustomer: boolean): Observable<IPostResponseDto<IOrder>> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });
    return this.httpClient.post<IPostResponseDto<IOrder>>(`${orderServiceUrl}/orders/costsummaryapproval`, costSummaryDto, { headers: headers });
  }

  getVesselCallDetails(id: string, isGroupCustomer: boolean = false): Observable<IVesselCallRequest> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });
    return this.httpClient.get<IVesselCallRequest>(`${orderServiceUrl}/orders/getrequestdetails?orderId=${id}`, { headers: headers });
  }

  getVesselCalls(pageSize: number, orderStatuses: string[] = [], isGroupCustomer: boolean = false, continuationToken: string | null = null): Observable<PageResponseDto<IVesselCallRequestIndex>> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });
    return this.httpClient.post<PageResponseDto<IVesselCallRequestIndex>>(`${orderServiceUrl}/orders/getvesselcallrequests`,
      {
        orderStatuses,
        pageSize,
        continuationToken
      },
      { headers: headers }
    );
  }

  getDraftVesselCalls(size: number, pageNumber: number, orderStatuses: string[] = []): Observable<IVesselCallRequestIndex[]> {
    return this.httpClient.get<IVesselCallRequestIndex[]>(`${orderServiceUrl}/orders/getvesselcallrequests?orderStatuses=${VesselCallJobStatus.Draft}&pageSize=${size}&pageNumber=${pageNumber}`);
  }

  saveOrderDraft(order: DraftVesselCallRequestDto): Observable<IVesselCallRequest> {
    return this.httpClient.post<IVesselCallRequest>(`${orderServiceUrl}/orders/savedraftrequest`, order);
  }

  submitOrder(orderId: string): Observable<unknown> {
    return this.httpClient.post(`${orderServiceUrl}/orders/submitrequest?orderId=${orderId}&timeStamp=${new Date().toISOString()}`, {});
  }

  getOrderIdByJobNumber(jobNumber: string): Observable<string> {
    return this.httpClient.get<string>(`${orderServiceUrl}/orders/getorderidforjob?jobNumber=${jobNumber}`);
  }

  generateMRDocument(mrNumber: number, cityCode: string, poNumber: string, poGroupCustomerCode: string) {
    return this.httpClient.get(`${orderServiceUrl}/cargo/generatemrdocument?mrNumber=${mrNumber}&cityCode=${cityCode}&poNumber=${poNumber}&poGroupCustomerCode=${poGroupCustomerCode}`,
      {
        responseType: 'blob'
      }
    ).pipe(
      catchError((error: any) => {
        throw new Error('There was a problem generating the document. Please try again later.');
      })
    );
  }

  getActivePortCallVessels(isGroupCustomer: boolean): Observable<string[]> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });
    return this.httpClient.get<string[]>(`${orderServiceUrl}/orders/getactiveportcallvessels`, { headers: headers });
  }

  getPortCallsByImo(imo: string | number, isGroupCustomer: boolean): Observable<IOrderServicePortCallDto[]> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });
    return this.httpClient.get<IOrderServicePortCallDto[]>(`${orderServiceUrl}/orders/getportcallsbyimo?imo=${imo}`, { headers: headers });
  }
  getPortCallsByImoList(imo: string[] | number[], isGroupCustomer: boolean): Observable<IOrderServicePortCallDto[]> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });
    return this.httpClient.post<IOrderServicePortCallDto[]>(`${orderServiceUrl}/orders/getportcallsbyimolist`, imo, { headers: headers });
  }

  getDaSummaryReportForPortal(isGroupCustomer: boolean): Observable<IDaTotalPerStageAndCurrencyDto[]> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });
    return this.httpClient.get<IDaTotalPerStageAndCurrencyDto[]>(`${orderServiceUrl}/orders/getdasummaryreportforportal`, { headers: headers });
  }

  getPortCodesForActiveJobs(customerCode: string): Observable<string[]> {
    const headers = new HttpHeaders({
      'Prt-Comp-Code': `${customerCode}`
    });
    return this.httpClient.get<string[]>(`${orderServiceUrl}/orders/getportcodesforjobs`, { headers: headers });
  }
}
